<template>
  <div>
    <div
      :class="cardProfileUserClasses"
      @click="changeProfile"
    >
      <div class="card--profile__img">
        <div :style="profileImgStyle"></div>
      </div>
      <div class="card--profile__user">
        <span>{{currentName}}</span>
        <span>{{userSubData}}</span>
      </div>
      <div :class="cardProfileActionClasses">
        <span>
          <font-awesome-icon icon="chevron-down"/>
        </span>
      </div>
    </div>
    <modal-profile-switch
      :id="modalId"
      :profileImgStyle="personImgStyle"
      :currentName="currentName"
      :userName="userName"
    />
  </div>
</template>

<script>
import { createPublicUri } from '../../utils/utilities';
import { isNotEmpty } from '../../utils/validators';
import { normalizeDisplayName } from '../../utils/formatterHelper';

const ModalProfileSwitch = () => import('./ModalProfileSwitch.vue');

export default {
  name: 'UserSwitcher',
  components: {
    ModalProfileSwitch,
  },
  props: {
    hideSwitch: Boolean,
    classes: String,
    uniqueId: String,
    showEmail: Boolean,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      userPlaceholder: require('@/assets/img/user-img_placeholder.png'),
      // eslint-disable-next-line global-require
      companyPlaceholder: require('@/assets/img/company-img_placeholder.png'),
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    placeholderImg() {
      if (this.isCompanyProfile) return this.companyPlaceholder;
      return this.userPlaceholder;
    },
    profileImgUrl() {
      const imageProfile = this.$store.getters['subject/getImage']('PROFILE');
      return isNotEmpty(imageProfile)
        ? createPublicUri(imageProfile.documentUpload.externalId)
        : this.placeholderImg;
    },
    personImgUrl() {
      const imageProfile = this.$store.getters['subject/getPersonImage']('PROFILE');
      return isNotEmpty(imageProfile)
        ? createPublicUri(imageProfile.documentUpload.externalId)
        : this.placeholderImg;
    },
    profileImgStyle() {
      return `background-image: url(${this.profileImgUrl});`;
    },
    personImgStyle() {
      return `background-image: url(${this.personImgUrl});`;
    },
    cardProfileUserClasses() {
      const baseClasses = 'card card--profile';
      if (isNotEmpty(this.classes)) {
        if (this.hideSwitch) return `${baseClasses} ${this.classes} pointer-events--none`;
        return `${baseClasses} ${this.classes}`;
      }
      if (this.hideSwitch) return `${baseClasses} pointer-events--none`;
      return baseClasses;
    },
    cardProfileActionClasses() {
      if (this.hideSwitch) return 'card--profile__action card--profile__action--simple d-none';
      return 'card--profile__action card--profile__action--simple';
    },
    userEmail() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].attribute.mainPecEmail.contactData;
      }
      return this.$store.getters['subject/currentPerson']?.personInfo.email;
    },
    userName() {
      return normalizeDisplayName(this.$store.getters['auth/currentName']);
    },
    currentName() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].info.companyName;
      }
      return normalizeDisplayName(this.userName);
    },
    modalId() {
      return this.uniqueId || 'profileSwitchModal';
    },
    serialCode() {
      if (this.activityStatus.operative !== false && this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return 'badge in creazione';
    },
    userSubData() {
      return this.showEmail ? this.userEmail : this.serialCode;
    },
  },
  methods: {
    changeProfile() {
      this.$bvModal.show(this.modalId);
    },
  },
};
</script>

<style scoped>

</style>
